(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/actionbar/assets/javascripts/actionbar.js') >= 0) return;  svs.modules.push('/components/sport/actionbar/assets/javascripts/actionbar.js');

'use strict';

var svs = svs || {};

(function(svs) {
  svs.sport = svs.sport || {};

  svs.sport.Actionbar = function(element) {
    var $el = element.find('#actionbar-content');

    if ($el.length === 0) {
      $el = element.find('#actionbar-content');
    }
    function button() {
      return {
        type: 'button',
        text: 'leftButton',
        icon: 'icon-close',
        visible: true,
        disabled: false,
        callback: function() {
          return true;
        }
      };
    }
    var currentContext = null;
    var $leftButton = null;
    var $centerButton = null;
    var $rightButton = null;
    var headerOptions = null;
    var defaultHeaderOptions = {
      cssClass: 'actionbar-default',
      leftButton: button(),
      centerButton: button(),
      rightButton: button(),
      bottomButton: button(),
      closeButton: true
    };
    function renderElement(button, options) {
      if (options.icon) {
        button.append('<i class="icon-default ' + options.icon + '"></i>');
      }

      if (options.disabled) {
        button.addClass('disabled');
      }

      if (options.visible) {
        button.css('visibility', 'visible');
      } else {
        button.css('visibility', 'hidden');
      }
    }
    function close(event) {
      if (event) {
        event.preventDefault();
      }
      hideBar();
      if (
        headerOptions &&
        headerOptions.closeButton &&
        Object.prototype.hasOwnProperty.call(
          headerOptions.closeButton,
          'callback'
        )
      ) {
        headerOptions.closeButton.callback.call(currentContext, this);
      }
    }
    function hideBar() {
      $el.parent().removeClass('actionbar-open');
      $el.off('click');
      $el.hide();
    }
    return {
      bindElements: function(options) {
        options.cssClass = options.cssClass || defaultHeaderOptions.cssClass;
        $el.html(svs.sport.actionbar.templates.actionbar(options));
        $leftButton = $el.find('#actionbar-button-1');
        $centerButton = $el.find('#actionbar-button-2');
        $rightButton = $el.find('#actionbar-button-3');
      },
      getPositionFromBottom: function() {
        var betslip = element.find('#betslip-wrapper');
        var border = betslip.css('border-top-width');
        var position = betslip.css('position');

        if (border === '5px') {
          return element.find('#betslip-footer').outerHeight() - 5; 
        } else if (position === 'relative') {
          return 0;
        }
        return element.find('#betslip-footer').outerHeight();
      },
      reposition: function() {
        if (svs.core.detect.formfactor.mobile()) {
          $el = element.find('#actionbar-content');
        }
      },
      show: function(options, context) {
        $el = element.find('#actionbar-content');

        $el.parent().addClass('actionbar-open');
        this.bindElements(options);
        currentContext = context;

        if (options.leftButton) {
          if (options.leftButton.type === 'text') {
            options.leftButton.typeText = true; 

            $leftButton.replaceWith('<span id="actionbar-button-1" class="actionbar-cell actionbar-full-cell padding-xs-left-1 actionbar-button-left">' +
                options.leftButton.text +
                '</span>');
            $leftButton = $el.find('#actionbar-button-1');
          }
          renderElement($leftButton, options.leftButton);
        }
        if (options.centerButton) {
          if (options.centerButton.type === 'text') {
            $centerButton.replaceWith('<span id="actionbar-button-2" class="actionbar-cell actionbar-button actionbar-description padding-xs-left">' +
                options.centerButton.text +
                '</span>');
            $centerButton = $el.find('#actionbar-button-2');
          }

          renderElement($centerButton, options.centerButton);
        }
        if (options.rightButton) {
          if (options.rightButton.type === 'text') {
            $rightButton.replaceWith('<span id="actionbar-button-3" class="actionbar-link">' +
                options.rightButton.text +
                '</span>');
            $rightButton = $el.find('#actionbar-button-3');
          }
          renderElement($rightButton, options.rightButton);
        }
        headerOptions = options;

        svs.components.animation.add(
          $el,
          'animate animate-show animate-time-short'
        );
        $el.show();

        if (svs.core.data.capabilities.formFactor === 'mobile') {
          $el.css('z-index', 90).css('bottom', 0);
          this.reposition();
        }
        $el.off('click');
        $el.on(
          'click',
          '#actionbar-button-1',
          $.proxy(this.buttonAction, this, headerOptions.leftButton)
        );
        $el.on(
          'click',
          '#actionbar-button-2',
          $.proxy(this.buttonAction, this, headerOptions.centerButton)
        );
        $el.on(
          'click',
          '#actionbar-button-3',
          $.proxy(this.buttonAction, this, headerOptions.rightButton)
        );
        $el.on('click', '#actionbar-button-4', $.proxy(close, this));
        $el.on(
          'click',
          '#actionbar-button-5',
          $.proxy(this.buttonAction, this, headerOptions.bottomButton)
        );
      },
      hide: function() {
        hideBar();
      },
      buttonAction: function(btn, e) {
        e.preventDefault();
        if (Object.prototype.hasOwnProperty.call(btn, 'callback')) {
          if (!btn.typeText && !btn.disabled) {
            btn.callback.call(currentContext, this);
          }
        }
      }
    };
  };
  svs.sport.actionbar = new svs.sport.Actionbar($('body'));
})(svs);


 })(window);